@import url(https://fonts.googleapis.com/css2?family=Catamaran:wght@100;500&family=Raleway&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* COLORS */
:root {
  --light-green: #c3e6cb;
  --mustard: #f9c126;
  --hover-green: #b1dfbb;
  --simple-green: #00ad28;
  --dark-green: #155724;
  --dark-red: #bd0000;
  --darker-red: #810000;
  --darker-green: #07200d;
  --transparent-grey: #00000079;
  --light-grey: #ccc;
}

/* FONTS */

/* MAIN */
* {
  color: #07200d;
  color: var(--darker-green);
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* GENERAL */
div > p {
  margin-bottom: 0px;
}
a,
button.link {
  color: #00ad28;
  color: var(--simple-green);
  background: none !important;
  border: none;
  padding: 0 !important;
}
a:hover,
button.link:hover {
  color: #155724;
  color: var(--dark-green);
  background: none !important;
  border: none;
  text-decoration: underline;
  padding: 0 !important;
}
.clickable {
  cursor: pointer;
}

/* NAVBAR */
.navbar {
  background-color: #155724;
  background-color: var(--dark-green);
}
.navbar-link,
.navbar-icon {
  color: white;
}
.navbar-link:hover,
.navbar-icon:hover {
  color: #ccc;
  color: var(--light-grey);
  text-decoration: none;
}
.navbar-name > h6,
.navbar-name > h6 > span {
  font-family: "Catamaran", sans-serif;
  font-weight: 100;
  color: white;
  font-size: 23px;
  margin-top: 5px;
  margin-bottom: 0px;
}
.navbar-name > img {
  margin-left: 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* CONTAINERS */
.container-fluid {
  margin-top: 20px;
}
.box {
  padding: 10px 20px; /*top-bottom, right-left,*/
  border-radius: 5px;
  background-color: #c3e6cb;
  background-color: var(--light-green);
  width: 100%;
}
.image-container {
  position: relative;
  text-align: center;
}
.top-right {
  background-color: white;
  color: #07200d;
  color: var(--darker-green);
  border-radius: 5px;
  border: solid 1px #00000079;
  border: solid 1px var(--transparent-grey);
  padding: 2px;
  position: absolute;
  top: 8px;
  right: 8px;
}
.ingredient-list {
  height: 200px;
  border-width: 5px;
  border-color: #155724;
  border-color: var(--dark-green);
  border-left-style: solid;
  background-color: #c3e6cb;
  background-color: var(--light-green);
}
.ingredient-list-mobile {
  height: 200px;
}
.category-title {
  border-bottom: solid 1px #00000079;
  border-bottom: solid 1px var(--transparent-grey);
}
.hover-opacity:hover {
  background: rgba(230, 230, 230, 0.8);
  opacity: 0.8;
}
.star-on {
  color: #f9c126;
  color: var(--mustard);
}
.star-off {
  color: black;
}

/* SIDEBAR */
.sidebar {
  background-color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 70px 0px 50px 0px; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  border-left: solid 1px #00000079;
  border-left: solid 1px var(--transparent-grey);
}
.sidebar-sticky {
  border-top: solid 1px #00000079;
  border-top: solid 1px var(--transparent-grey);
  position: relative;
  top: 0;
  height: 93%;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}
@media (max-width: 575px) {
  .sidebar {
    top: 5rem;
  }
}

/* BAR */
.stick-bottom {
  position: absolute;
  bottom: 5px;
}
p.empty-bar {
  margin: 20px;
}
.badge-pill {
  background-color: #155724;
  background-color: var(--dark-green);
}
.sidebar-btn {
  margin-top: 8px;
}
.sidebar-btn > button {
  width: 50%;
}
.my-bar-btn {
  margin: 20px 0px;
}
.my-bar-btn > button {
  padding: 8px 0px;
  font-size: 20px;
  width: 100%;
}
.badge-bar {
  border-radius: 10px;
  position: relative;
  bottom: 10px;
  background-color: #f9c126;
  background-color: var(--mustard);
  color: #07200d;
  color: var(--darker-green);
}
.plus-sign > i {
  color: #155724;
  color: var(--dark-green);
}

/* BUTTONS */
.btn-cocktailme-red {
  background-color: #bd0000;
  background-color: var(--dark-red);
  border-radius: 3px;
  color: white;
  font-size: 12px;
}
.btn-cocktailme-red:hover {
  background-color: #810000;
  background-color: var(--darker-red);
  color: white;
}
.btn-cocktailme {
  background-color: #155724;
  background-color: var(--dark-green);
  border-radius: 3px;
  color: white;
  font-size: 12px;
  font-family: "Raleway";
  text-decoration: none;
  border-width: 0px;
  padding: 8px;
}
.btn-cocktailme:hover {
  color: white;
  background-color: #07200d;
  background-color: var(--darker-green);
}
.btn-cocktailme-clear {
  background-color: white;
  color: #155724;
  color: var(--dark-green);
  border: 1px solid #00000079;
  border: 1px solid var(--transparent-grey);
  border-radius: 3px;
  font-size: 12px;
}
.btn-cocktailme-clear:hover {
  background-color: #155724;
  background-color: var(--dark-green);
  color: white;
}

/* PAGINATION */
.pagination > li > button,
.pagination > li > button > i {
  background-color: white;
  color: #155724;
  color: var(--dark-green);
}
.pagination > li.disabled > button > i {
  color: #00000079;
  color: var(--transparent-grey);
}
.pagination > li > button:focus,
.pagination > li > button:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}
.pagination > .active > button {
  color: white;
  background-color: #155724 !important;
  background-color: var(--dark-green) !important;
  border: solid 1px #155724 !important;
  border: solid 1px var(--dark-green) !important;
}
.pagination > .active > button:hover {
  background-color: #155724 !important;
  background-color: var(--dark-green) !important;
  border: solid 1px #155724;
  border: solid 1px var(--dark-green);
}

/* AUTH */
.btn-auth {
  width: 100%;
  height: 50px;
}
.input-auth {
  height: 50px;
}
.auth-container {
  width: calc(100% - 50px);
  max-width: 500px;
  margin: auto;
  min-height: 100vh;
}
.profile-container {
  min-height: 100vh;
}
.profile {
  width: calc(100% - 50px);
  max-width: 500px;
  margin: auto;
}
.favorites-container {
  width: calc(100% - 100px);
  max-width: 900px;
  margin: auto;
}

/*MAIN PAGE*/
.element > div.card {
  background-color: #c3e6cb;
  background-color: var(--light-green);
  width: 100%;
  margin: auto;
}
h5.card-title {
  margin-bottom: 0px;
}
p.card-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.caption,
.nav-name {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
img.disabled {
  opacity: 0.3;
}
.figure {
  margin-bottom: 10px;
}
.cocktails,
.element {
  margin-left: 0px;
}

/* TOAST */
.Toastify__toast-body {
  color: white;
}

/* FOOTER */
.footer {
  position: static;
  left: 0;
  bottom: 0;
  margin-top: 30px;
  padding: 10px;
  width: 100%;
  background-color: #155724;
  background-color: var(--dark-green);
}
.footer-column > h6 {
  color: white;
  font-size: 12px;
  margin-bottom: 3px;
}
.footer-column > p {
  color: white;
  font-size: 10px;
  margin-bottom: 5px;
}
.footer-column > p > a {
  color: white;
  font-size: 10px;
  text-decoration: underline;
  margin-left: 2px;
}
.footer-column > a > i {
  color: white;
  margin-left: 10px;
}
.footer-column > h6 > a {
  color: white;
  text-decoration: underline;
}

/* PRIVACY */
.privacy {
  width: calc(100% - 50px);
  max-width: 1000px;
  margin: auto;
}
.privacy > h3 {
  margin-bottom: 14px;
  font-weight: bold;
}
.privacy > h5 {
  font-weight: bold;
  margin-top: 14px;
  margin-bottom: 14px;
}

